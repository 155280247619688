import AuthProvider from "@app/components/bsso/AuthProvider";
import Content from "@app/components/common/content/Content";
import Navbar from "@app/components/common/navbar/Navbar";
import { Suspense } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Outlet } from "react-router-dom";

import { LMThemeProvider, ToastContainer } from "@launchmetrics/lm-ui";

function RootLayout() {
	const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } } });
	return (
		<QueryClientProvider client={queryClient}>
			<LMThemeProvider>
				<ToastContainer />
				<AuthProvider>
					<Navbar />
					<Content>
						<Suspense fallback={<p>Loading...</p>}>
							<Outlet />
						</Suspense>
					</Content>
				</AuthProvider>
			</LMThemeProvider>
		</QueryClientProvider>
	);
}

export default RootLayout;
